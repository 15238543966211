import { Box } from "@mui/material";
import { FC, useCallback, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper as SwiperSlider, SwiperSlide, SwiperRef, SwiperProps } from "swiper/react";
import { PrevArrow, NextArrow } from "./compounds/Arrow";
import { SwiperModule } from "swiper/types";

type Props = {
  withPagination?: boolean;
  ItemComponent: FC<any>;
  items: (unknown & { id: string })[];
} & SwiperProps;

export const SliderDefault = (p: Props) => {
  const { items, ItemComponent, withPagination, ...props } = p;

  const $prev = useRef();
  const $next = useRef();
  const $pagination = useRef<HTMLDivElement>(null);
  const $slider = useRef<SwiperRef>(null);

  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);

  const handlePrev = useCallback(() => {
    $slider.current?.swiper?.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    $slider.current?.swiper?.slideNext();
  }, []);

  // useEffect(() => {
  //   $slider.current?.swiper.on("slideChange", (e: { isBeginning: boolean; isEnd: boolean }) => {
  //     setPrevDisabled(e.isBeginning);
  //     setNextDisabled(e.isEnd);
  //   });
  // }, []);

  const { ...other } = props;

  return (
    <>
      <SwiperSlider
        ref={$slider}
        slidesPerView={1}
        spaceBetween={30}
        onSlideChange={(e) => {
          setPrevDisabled(e.isBeginning);
          setNextDisabled(e.isEnd);
        }}
        breakpoints={{
          600: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
          1400: {
            slidesPerView: 5,
          },
          1600: {
            slidesPerView: 5,
          },
        }}
        modules={([!!withPagination && Pagination] as SwiperModule[]).filter(Boolean)}
        pagination={
          withPagination && {
            el: $pagination.current,
            clickable: true,
          }
        }
        {...other}
      >
        {items.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <ItemComponent {...item} />
            </SwiperSlide>
          );
        })}
        {withPagination && (
          <Box
            ref={$pagination}
            sx={{
              textAlign: "center",
              "--swiper-theme-color": "red",
            }}
          />
        )}
      </SwiperSlider>
      <PrevArrow disabled={prevDisabled} onClick={handlePrev} ref={$prev} />
      <NextArrow disabled={nextDisabled} onClick={handleNext} ref={$next} />
      {/* {isSM && (
        <div className="hotels-pagination" style={{ marginTop: "20px", marginBottom: "40px", textAlign: "center" }} />
      )} */}
    </>
  );
};
