import { useMemo } from "react";

import { FreeMode, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

export function useSliderSettings() {
  return useMemo(
    () => ({
      spaceBetween: 0,
      slidesPerView: 3,
      freeMode: true,
      loop: false,

      navigation: { nextEl: ".arrow-right", prevEl: ".arrow-left" },
      breakpoints: {
        480: {
          slidesPerView: 4
        },
        600: {
          slidesPerView: 5
        },
        780: {
          slidesPerView: 6
        },
        1024: {
          slidesPerView: 8
        }
      },
      modules: [FreeMode, Navigation]
    }),
    []
  );
}
