import { Typography, TypographyPropsVariantOverrides } from "@mui/material";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

import type { SxProps } from "@mui/system";
import type { Theme } from "@mui/material/styles/createTheme";
import type { Variant } from "@mui/material/styles/createTypography";
import type { OverridableStringUnion } from "@mui/types";
import { agreementDoc } from "../../../utils/constants";

interface Props {
  body?: any;
  paragraphSx?: SxProps<Theme>;
  paragraphVariant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides> | undefined;
  isForAgreementGeneration?: boolean;
  isGridItem?: boolean;
}

export function ContentRendererBody({
  body,
  paragraphVariant,
  paragraphSx,
  isForAgreementGeneration,
  isGridItem,
}: Props) {
  if (!body) return null;

  return (
    <BlocksRenderer
      blocks={{
        paragraph: (p) => (
          <Typography
            component="p"
            variant={paragraphVariant || "body1"}
            sx={{
              ...paragraphSx,
              whiteSpace: "pre-wrap",
              wordBreak: "normal",
              ...(isForAgreementGeneration && {
                fontFamily: agreementDoc.fontFamily,
                fontSize: isGridItem ? agreementDoc.gridItem.paragraph.fontSize : agreementDoc.paragraph.fontSize,
                lineHeight: agreementDoc.paragraph.lineHeight,
                textAlign: isGridItem ? agreementDoc.gridItem.paragraph.textAlign : agreementDoc.paragraph.textAlign,
              }),
            }}
            {...p}
          />
        ),
        ...(isForAgreementGeneration && {
          heading: ({ children, level }) => {
            switch (level) {
              case 1:
                return (
                  <Typography variant="h1" sx={{ fontFamily: agreementDoc.fontFamily }}>
                    {children}
                  </Typography>
                );
              case 2:
                return (
                  <Typography variant="h2" sx={{ fontFamily: agreementDoc.fontFamily }}>
                    {children}
                  </Typography>
                );
              case 3:
                return (
                  <Typography variant="h3" sx={{ fontFamily: agreementDoc.fontFamily }}>
                    {children}
                  </Typography>
                );
              case 4:
                return (
                  <Typography variant="h4" sx={{ fontFamily: agreementDoc.fontFamily }}>
                    {children}
                  </Typography>
                );
              case 5:
                return (
                  <Typography variant="h5" sx={{ fontFamily: agreementDoc.fontFamily }}>
                    {children}
                  </Typography>
                );
              case 6:
                return (
                  <Typography variant="h6" sx={{ fontFamily: agreementDoc.fontFamily }}>
                    {children}
                  </Typography>
                );
              default:
                return (
                  <Typography variant="h1" sx={{ fontFamily: agreementDoc.fontFamily }}>
                    {children}
                  </Typography>
                );
            }
          },
        }),
      }}
      content={body}
    />
  );
}
