/* eslint-disable @next/next/no-img-element */
import React, { forwardRef } from "react";
import prevArrow from "./prev.svg";
import nextArrow from "./next.svg";
import { fixAssetPath } from "../../../../fe-ui/utils/fixAssetPath";
import { Box, SxProps, Theme } from "@mui/material";

const Arrow = forwardRef(function Arrow(
  {
    disabled,
    src,
    sx,
    ...other
  }: {
    disabled?: boolean;
    src: string;
    sx: SxProps<Theme>;
    onClick?: (e?: any) => void;
  },
  ref
) {
  return (
    <Box
      sx={{
        width: "40px",
        height: "40px",
        padding: 1.5,
        textAlign: "center",
        boxShadow: "-2px 8px 20px 2px rgba(145, 158, 171, 0.4)",
        borderRadius: "50%",
        backgroundColor: "white",
        zIndex: 1,
        cursor: "pointer",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        ...(disabled
          ? {
              opacity: 0.5,
            }
          : {}),
        ...sx,
      }}
      {...other}
      ref={ref}
    >
      <img style={{ width: "100%", height: "100%" }} alt={""} src={fixAssetPath(src)} />
    </Box>
  );
});

type Props = {
  onClick: (e?: any) => void;
  disabled?: boolean;
};

export const PrevArrow = forwardRef(function PrevArrow(props: Props, ref) {
  const { onClick, disabled } = props;
  return (
    <Arrow
      ref={ref}
      disabled={disabled}
      sx={{ left: 5 }}
      src={prevArrow.src}
      onClick={disabled ? undefined : onClick}
    />
  );
});

export const NextArrow = forwardRef(function NextArrow(props: Props, ref) {
  const { onClick, disabled } = props;
  return (
    <Arrow
      ref={ref}
      disabled={disabled}
      sx={{ right: 5 }}
      src={nextArrow.src}
      onClick={disabled ? undefined : onClick}
    />
  );
});
