import Image from "next/image";
import { StyleHTMLAttributes } from "react";
import { HotelAttributeDto, HotelUnitGroupAttributeDto } from "../../../api/aggregation-client/service";

type Props = {
  size: 32 | 64 | 128;
  alt?: string;
  style?: StyleHTMLAttributes<"img">;
  attribute: HotelAttributeDto | HotelUnitGroupAttributeDto;
};

export function IconAttribute(props: Props) {
  const { size = 64, attribute } = props;

  const icon = (attribute as HotelUnitGroupAttributeDto).icon;
  const padding = 0;
  // if (!icon || code === "check") {
  //   // icon = attributeCodeToIconMap.check
  //   padding = 8;
  // }
  return (
    <Image
      draggable={false}
      src={icon}
      style={{
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: "5px",
        padding,
      }}
      height={size}
      width={size}
      alt=""
    />
  );
}
