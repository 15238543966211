type TextAlign = "start" | "end" | "left" | "right" | "center" | "justify" | "match-parent";

export const agreementDoc = {
  fontFamily: "Arial",
  paragraph: {
    fontSize: "14.67px", // Word's 11pt converted to 14.67px
    lineHeight: 1.15, // closest approximation to Word's line spacing 1.0
    textAlign: "justify" as TextAlign,
  },
  textBlock: {
    spacing: "14.67px", // equal to generic paragraph fontSize
  },
  gridItem: {
    paragraph: {
      fontSize: "13.33px", // Word's 10pt converted to 13.33px
      textAlign: "left" as TextAlign,
    },
    spacing: "13.33px", // equal to gridItem's paragraph fontSize
  },
};
