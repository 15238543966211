import React from "react";
import { Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

type Props = {
  items?: any[];
};

export const PropertyPromotionLabel = (props: Props) => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const ws = isXS ? "normal" : "nowrap";
  const ov = isXS ? "auto" : "hidden";
  const mw = isXS ? "auto" : "150px";

  return (
    <Stack sx={{ position: "absolute", top: "10px", left: "10px" }}>
      <>
        {props.items &&
          props.items.length > 0 &&
          props.items.map((item, index) => (
            <Typography
              key={item?.id || index}
              variant={"caption"}
              sx={{
                color: "#fff",
                padding: "2px 5px",
                marginBottom: "5px",
                borderRadius: "5px",
                backgroundColor: item?.backgroundColor,
                whiteSpace: ws,
                overflow: ov,
                maxWidth: mw,
                width: "fit-content",
                textOverflow: "ellipsis"
              }}
            >
              <Tooltip title={item?.title} arrow placement="top">
                <span>{item?.title}</span>
              </Tooltip>
            </Typography>
          ))}
      </>
    </Stack>
  );
};
