import { useMemo } from "react";
import { LazyLoadImageProps } from "react-lazy-load-image-component";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { BoxProps } from "@mui/material";
import { env } from "../../utils/env";

// TODO: move to somewhere else
const IMGPROXY_URL = env(
  "NEXT_PUBLIC_IMGPROXY_URL",
  process.env.NEXT_PUBLIC_IMGPROXY_URL || "https://imgproxy.seaya.az"
);

type ImageProps = LazyLoadImageProps &
  BoxProps & {
    src: string;
    alt: string;

    className?: string;
    width?: number;
    height?: number;
    loading?: "eager" | "lazy";
    size: "small" | "medium" | "large";
  };

type ImageVariant = {
  width: number;
  height: number;
};

type ImgProxyOpts = {
  width: number;
  height: number;
};

const getImageProxyParams = ({ width, height }: ImgProxyOpts) => `insecure/rs:fill:${width}:${height}/g:ce/plain`;

export const getImageProxyPath = (path, opts: ImgProxyOpts) => `${IMGPROXY_URL}/${getImageProxyParams(opts)}/${path}`;

function getVariantSrc(variant: ImageVariant & { src: string; pathOnly?: boolean }): string {
  const w = variant.pathOnly ? "" : ` ${variant.width}w`;
  return `${getImageProxyPath(variant.src, {
    width: variant.width,
    height: variant.height,
  })}${w}`;
}

const sizes = {
  s: {
    small: {
      width: 600,
      height: 600,
    },
    medium: {
      width: 600,
      height: 600,
    },
    large: {
      width: 600,
      height: 600,
    },
  },
  m: {
    small: {
      width: 500,
      height: 500,
    },
    medium: {
      width: 800,
      height: 800,
    },
    large: {
      width: 900,
      height: 900,
    },
  },
  l: {
    small: {
      width: 400,
      height: 400,
    },
    medium: {
      width: 800,
      height: 800,
    },
    large: {
      width: 1200,
      height: 1200,
    },
  },
};

export const useSize = (size) => {
  const theme = useTheme();

  const isS = useMediaQuery(theme.breakpoints.between(300, 600));
  const isM = useMediaQuery(theme.breakpoints.between(601, 900));
  const isL = useMediaQuery(theme.breakpoints.between(901, 3000));

  switch (true) {
    case isS: {
      return sizes.s[size];
    }
    case isM: {
      return sizes.m[size];
    }
    case isL: {
      return sizes.l[size];
    }
    default:
      return { width: 100, height: 100 };
  }
};

export const S3ImageLightSrc = (src, size) => {
  let { width, height } = useSize(size) || {};
  const pathOnly = true;
  return getVariantSrc({ src, width, height, pathOnly });
};

export const S3ImageLight = (props: ImageProps) => {
  const { src, alt: altPassed, className, size, ...rest } = props;
  let { width, height } = useSize(size) || {};

  const { srcSet, alt } = useMemo(() => {
    const alt = altPassed || "";
    const std = [{ width, height, src }];
    const srcSet = std.map(getVariantSrc).join(", ");

    return { alt, srcSet };
  }, [altPassed, src, width, height]);

  return (
    <Box
      component={"span"}
      sx={{
        lineHeight: 1,
        display: "block",
        overflow: "hidden",
        position: "relative",
        height: "100%",
        "& .wrapper": {
          width: 1,
          height: 1,
          backgroundSize: "cover !important",
        },
      }}
    >
      <Box
        alt={alt}
        component={"img"}
        sx={{ width: 1, height: 1, objectFit: "cover" }}
        draggable={false}
        srcSet={srcSet}
        src={S3ImageLightSrc(src, size)}
      />
    </Box>
  );
};
