import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  useMediaQuery,
  Skeleton,
} from "@mui/material";

import { useTheme } from "@mui/system";

const IMAGE_HEIGHT_MOBILE = 300;
const IMAGE_HEIGHT_DEFAULT = 200;

export function AttributeSliderItemSkeleton() {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const imageHeight = isXS ? IMAGE_HEIGHT_MOBILE : IMAGE_HEIGHT_DEFAULT;

  return (
    <Card elevation={0} sx={{ maxWidth: 420 }}>
      <CardMedia>
        <Skeleton variant={"rectangular"} sx={{ height: imageHeight }} />
      </CardMedia>
      <CardContent>
        <Typography gutterBottom variant={"h5"} component={"div"}>
          <Skeleton sx={{ height: 30, width: "60%" }} />
        </Typography>
        <Typography variant={"body2"} color={"text.secondary"}>
          <Skeleton sx={{ height: 22 }} />
        </Typography>
        {/* <Skeleton sx={{ height: 30, width: "60%" }} /> */}
        {/* <Skeleton sx={{ height: 22 }} /> */}
        {/* <Skeleton sx={{ height: 20, width: "50%" }} /> */}
      </CardContent>
      <CardActions>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <Button disabled fullWidth size={"medium"}>
              <Skeleton variant={"rectangular"} sx={{ height: 22, width: "100%", borderRadius: 1.5 }} />
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
