// @mui
import { Box, Typography } from "@mui/material";

import { useTheme } from "@mui/system";
import { AttributeSliderItemSkeleton } from "./AttributeSliderItemSkeleton";
import { Link } from "../../../../../fe-ui/atoms/Link/Link";
import { IconAttribute } from "../../../AttributeIcon/AttributeIcon";
import { HotelAttributeDto, HotelUnitGroupAttributeDto } from "../../../../../api/aggregation-client/service";

// ----------------------------------------------------------------------

type Props = {
  attribute: HotelUnitGroupAttributeDto | HotelAttributeDto;
  isLoading?: boolean;
};

export function AttributeSliderItem(props: Props) {
  const { attribute, isLoading } = props;
  const {
    title,
    id,
    //code
  } = attribute;
  const theme = useTheme();

  if (isLoading) {
    return <AttributeSliderItemSkeleton />;
  }

  return (
    <Link
      draggable={false}
      data-testid={`feature-card-${id}`}
      key={id}
      href={`/search?filters=${id}`}
      underline={"none"}
      data-ym={`FEATURED_ATTRIBUTE`.toUpperCase()}
    >
      <Box
        sx={{
          width: "100px",
          textAlign: "center",
          userSelect: "none",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
      >
        <IconAttribute alt={title} attribute={attribute} size={64} />
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.text.primary,
            fontSize: 10,
          }}
        >
          {title}
        </Typography>
      </Box>
    </Link>
  );
}
