import wv1 from "./removeme/wv1.png";
import wv2 from "./removeme/wv2.png";
import lh from "./removeme/lighthouse.png";
import glamping from "./removeme/glamping.png";
import cube from "./removeme/cube-hotel.png";
import seabreeze from "./removeme/seabreeze.png";
import park from "./removeme/park.png";
import palazzodelsol from "./removeme/palazzo-del-sol.png";
import palazzodelluna from "./removeme/palazzo-del-luna.png";
import grand from "./removeme/the-grand.png";
import privatevillas from "./removeme/privatevillas.png";
import wr from "./removeme/white-residence.png";
import rosa from "./removeme/rosa.png";
import verdi from "./removeme/palazzo-verdi.png";
import ventus from "./removeme/palazzo-ventus.png";

export const hotelIdToLogoMap = {
  "01H04SCVH5HVBAQ74CAADNX746": wv1.src,
  "01H17E2X1ZQWTX5H6THVWPB6N1": wv2.src,
  "01H4GY950R403Q7EPNZGETCZA9": lh.src,
  "01H6TRTNK30NHMFBWYQP2HEP1S": glamping.src,
  "01H6EDKQ2Q8SDH88RNC97TCWKE": cube.src,
  "01H6BBX5B8N3WGP73XKG0GEJV1": seabreeze.src,
  "01H77ZDEF41DRBAKAW1W68AP3F": park.src,
  "01HMV3HB8D0J42KPMBXK4AMF7R": palazzodelsol.src,
  "01HQTXHD362Z8Z9XZ9GZV6WPM4": palazzodelluna.src,
  // White Residences
  "01HYN63B2H7G61MFS2507Y0WY2": wr.src,
  // The Grand
  "01HYN9XWXFQJC5CYSHKV4MYMQ6": grand.src,
  // Private Villas
  "01HRVJKP2BDG6JV04CMCFP7ZAR": privatevillas.src,
  // Palazzo del Rosa
  "01HWAFW0WC3X5GAPRQ4GK0AFWR": rosa.src,
  // Palazzo del Verdi
  "01HYN81KWNZC63TP3P95JVVXV1": verdi.src,
  // Palazzo del ventus
  "01HWG52KD4F27G19FP34PNZHR5": ventus.src,
};

export const hotelLogoSizeMap = {
  "01H77ZDEF41DRBAKAW1W68AP3F": 50,
};
