import React from "react";
import nextArrow from "./next.svg";
import { fixAssetPath } from "../../../../fe-ui/utils/fixAssetPath";

export function SampleNextArrow(props) {
  const { className, style, onClick, bgColor } = props;
  return (
    <img
      className={className}
      style={{
        ...style,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: 0,
        width: 30,
        height: 30,
        padding: 10,
        boxShadow: "-2px 8px 20px 2px rgba(145, 158, 171, 0.4)",
        borderRadius: "15px",
        backgroundColor: bgColor,
        zIndex: 1,
        cursor: "pointer",
      }}
      src={fixAssetPath(nextArrow.src)}
      onClick={onClick}
    />
  );
}
